<template>
    <div v-if="showModal" class="modal fade show" style="display: block" id="modal-enter-email" tabindex="-1" aria-labelledby="modal-enter-email-label">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body m-0 p-2">
            <div class='w-100 p-3 text-center'>Please confirm the email address for Petals Rewards notifications.</div>
            <input type="text" v-model="emailAddress" @keyup="validateEmail" placeholder="E-mail Address" class="form-control d-block w-100 mb-2 p-2 text-center" />
            <div class="text-danger text-center" v-if="displayErrorMessage">Please enter a valid email address</div>
          </div>
          <div class="modal-footer">
            <input
              type="submit"
              @click="changeLoyaltySignUp"
              class="btn btn-primary"
              value="OK"
            />
            <input
              type="submit"
              @click="cancelAndReset"
              class="btn btn-primary"
              value="CANCEL"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop fade show"></div>
</template>
<script>
import axios from "axios";
import { misc, loyalty } from "@/mixins";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EnterEmailRewards",
  mixins: [misc, loyalty],
  props: {
    submitFn: {
      type: Function,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailAddress: '',
      displayErrorMessage: false,
    };
  },
  computed: {
    ...mapActions(["fetchCartAndMeta"]),
    ...mapGetters(["loggedinUserDetails"]),
  },
  mounted() {
    this.resetModal();
  },
  methods: {
    hideMe() {
      this.$emit('update:showModal', false);
    },
    resetModal() {
      this.emailAddress = this.loggedinUserDetails.email.trim();
      this.displayErrorMessage = this.emailAddress !='' ? false : true;
      this.$emit('update:showModal', true);
    },
    cancelAndReset() {
      this.resetModal();
      this.hideMe();
    },
    changeLoyaltySignUp() {
      this.isLoading = true;
      this.emailAddress = this.emailAddress.trim();
      if (this.emailAddress != '') {
        axios
          .post(process.env.VUE_APP_API_BASE + "auth/changeLoyaltySignUp", 
          {
            status: 1,
            email: this.emailAddress
          },
          {
              headers: {
              "Content-type": "application/json;multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
              },
          }).then((response) => {
            if (response.data.error) {
              this.emitter.emit("displayFlash", {
                message: response.data.message,
                level: "error",
              });
            } else {
              this.emitter.emit("displayFlash", {
                message: response.data.message,
                level: "success",
              });
              let elements = document.querySelectorAll('.sign-me-up');
              if (elements.length > 0) {
                elements.forEach((element) => {
                  element.classList.add('d-none');
                })
              }
              this.fetchCustomerLoyaltyInfo();
              this.fetchCartAndMeta();
              this.submitFn();
            }
            this.isLoading = false;
            this.hideMe();
          }).catch((err) => {
            console.log('An error occurred while retriving the customer information. ', err);
            this.isLoading = false;
            this.hideMe();
          });
      }
    },
    validateEmail() {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailAddress = String(this.emailAddress).toLocaleLowerCase();
      this.displayErrorMessage = false;
      if (!emailRegex.test(this.emailAddress)) {
        this.displayErrorMessage = true;
      }
    },
  },
};
</script>
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}

.buttons .btn:hover,
.btn[type="submit"]:hover,
#account-login .well .btn:hover {
  border: 2px solid #9f9f9f;
  background: #9f9f9f;
  color: #fff;
}
.buttons .btn,
.btn[type="submit"],
#account-login .well .btn {
  border: 2px solid #9f9f9f;
  border-radius: inherit;
  background: inherit;
  color: #797979;
  padding: 8px 25px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}
h4 {
  font-size: 1.2 rem;
}
thead {
  background: #efefef;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.modal-footer {
  justify-content: center !important;
}

.btn[type="submit"] {
  border-radius: 0px;
}
</style>