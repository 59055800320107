<template>
    <div id="install-pwa" class="container">
        <div id="download-app" class="mx-auto">
            <Popper v-if="show && isLoggedIn && !isOpera() && $route.name !== 'Carts' && $route.name !== 'Checkout'" :arrow="true" :hover="true" content="Use the install button to download our web app to install a shortcut to your phone or computer and to enable notifications which will allow you to receive real-time updates, news, and exclusive promotions!" style="margin: 0px; border-right-width: 0px; text-align: center;">
                <a @click="installPWA" class="text-decoration-underline">Download the App</a>
            </Popper>
        </div>
    </div>
</template>
<script>
    import { pwa } from "@/mixins";
    import { mapGetters } from "vuex";
    import Popper from "vue3-popper";
    export default {
        name: "InstallPWA",
        mixins: [pwa],
        components: {
            Popper,
        },
        data() {
            return {
                show: false,
            }
        },
        computed: {
            ...mapGetters(["isLoggedIn"]),
        },
        beforeMount() {
            window.addEventListener("beforeinstallprompt", (e) => {
                e.preventDefault();
                this.installEvent = e;
                this.show = true;
            });
        },
        methods: {
            dismissPWAPrompt() {
                this.show = false;
            },
        },
    };
</script>
<style scoped>
    div#download-app {
        max-width: 158px;
    }
    div#download-app a {
        color: #404040;
    }
    div#download-app a:hover {
        color: #f48062;
        cursor: pointer;
    }
</style>

