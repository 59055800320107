<template>
    <div class="container text-center text-md-end">
        <div class="py-3">
            <a v-if="canEnrollLoyalty" href="/petals-rewards" class="btn btn-theme w-100 w-md-auto">JOIN PETALS REWARDS</a>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    export default {
        name: "JoinRewardsProgram",
        computed: {
            ...mapGetters(["canEnrollLoyalty"]),
        },
    };
</script>